<template>
  <div class="wrapper w-full">
    <header class="home-page header" :class="{ 'open-menu': openedSidebar }">
      <div class="content content_in">
        <div class="header__menu" @click="openedSidebar = !openedSidebar">
          <div class="icon-menu" :class="{ active: openedSidebar }">
            <div class="sw-topper"></div>
            <div class="sw-bottom"></div>
            <div class="sw-footer"></div>
          </div>
        </div>
        <router-link to="/" class="land-header__logo">
          <img :src="require('@/assets/images/entry/logo-white.svg')" alt="" />
        </router-link>
        <div class="header__manager">
          <div class="manager">
            <div class="manager__item">
              <a href="https://telegram.me/Robert_ph" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__item pl-0">
              <a href="https://telegram.me/Leo31PH" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="https://telegram.me/publisher_push_house" target="_blank" class="manager__link">@publisher_push_house</a>
            </div>
          </div>
        </div>
        <nav class="header__entry entry-menu">
          <ul>
            <li>
              <router-link to="/login" class="btn _entry">{{ $t("landing.login") }}</router-link>
            </li>
            <li>
              <router-link to="/registration" class="btn _registration">{{ $t("landing.register") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="header__lang language">
          <select-language />
        </div>

        <router-link to="/login" class="header__login">
          <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.25 20.2552H21.75C25.7541 20.2552 29 23.494 29 27.4892C29 28.2882 28.3508 28.936 27.55 28.936C26.8064 28.936 26.1935 28.3775 26.1098 27.6579L26.0926 27.2342C25.9652 25.0401 24.2045 23.2833 22.0056 23.1562L21.75 23.1488H7.25C4.84756 23.1488 2.9 25.0921 2.9 27.4892C2.9 28.2882 2.25081 28.936 1.45 28.936C0.649187 28.936 0 28.2882 0 27.4892C0 23.5991 3.07734 20.4262 6.93551 20.2619L7.25 20.2552H21.75H7.25ZM14.5 0C19.3049 0 23.2 3.88653 23.2 8.68081C23.2 13.4751 19.3049 17.3616 14.5 17.3616C9.69512 17.3616 5.8 13.4751 5.8 8.68081C5.8 3.88653 9.69512 0 14.5 0ZM14.5 2.89359C11.2967 2.89359 8.69995 5.48461 8.69995 8.6808C8.69995 11.877 11.2967 14.468 14.5 14.468C17.7032 14.468 20.3 11.877 20.3 8.6808C20.3 5.48461 17.7032 2.89359 14.5 2.89359Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </header>
    <main>
      <section class="offer error-page h-100">
        <div class="error-block">
          <img class="error-img" :src="require('@/assets/images/home/404.svg')" alt="" />
          <span class="error-title">404</span>
          <p class="error-subtitle">{{ $t("error_page.title") }}</p>
          <p class="error-text">
            {{ $t("error_page.text") }}
          </p>
          <router-link to="/" class="error-button btn _bg-green _big">
            <svg v-svg symbol="return" width="22" height="16" size="0 0 22 16" role="info" />
            {{ $t("error_page.button") }}
          </router-link>
        </div>
      </section>
    </main>
    <aside class="land-sidebar" :class="{ active: openedSidebar }">
      <div class="sidebar__content mt-2">
        <select-language leftMenu />

        <nav class="sidebar__entry entry-menu">
          <ul>
            <li>
              <router-link to="/login" class="btn _entry">{{ $t("landing.login") }}</router-link>
            </li>
            <li>
              <router-link to="/registration" class="btn _registration">{{ $t("landing.register") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="sidebar__manager">
          <div class="manager">
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a href="https://telegram.me/Leo31PH" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a href="https://telegram.me/Robert_ph" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="https://telegram.me/publisher_push_house" target="_blank" class="manager__link">@publisher_push_house</a>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div class="menu-bg" @click="openedSidebar = false" :class="{ active: openedSidebar }"></div>
  </div>
</template>
<script>
import SelectLanguage from "@/components/SelectLanguage.vue";

export default {
  name: "ErrorPage",
  components: {
    SelectLanguage,
  },
  beforeMount() {
    document.title = `Partners.House | 404`;
  },
  beforeUpdate() {
    document.title = `Partners.House | 404`;
  },
  data() {
    return {
      openedSidebar: false,
    };
  },
  watch: {
    openedSidebar(val) {
      if (val) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    close() {
      if (!this.openedSidebar) {
        return;
      }
      this.openedSidebar = false;
    },
  },
};
</script>
<style scoped>
@import "../assets/css/landing.css";

.land-sidebar {
  width: 240px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 191;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1920px) {
  .land-sidebar {
    left: 50%;
    transform: translateX(-960px);
  }
}
@media screen and (max-width: 1200px) {
  .land-sidebar {
    width: 280px;
    transform: translateX(-100%);
    transition: ease-out 0.3s;
  }
  .land-sidebar.active {
    transition: ease-in 0.4s;
    transform: translateX(0);
  }
}
@media screen and (min-width: 1200px) {
  .land-sidebar {
    display: none;
  }
}

.header__logo {
  display: block;
}
.header__logo img {
  display: block;
  width: 144px;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .header__logo {
    display: block;
  }
}
</style>
